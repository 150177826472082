<template>
  <div class="q-pa-md">

    <div class="row margin-lg-div justify-center">
      <div class="col-md-12">
        <div
          div
          :class="fontSize + ' text-center q-mb-lg'"
          id="heading-info-h2"
        >
          {{ studyObject.study.name}} <br />
        </div>
      </div>
    </div>


    <div class="column items-center container">
      <div
        :class="
          [item.show ? 'col-md-12 full-width ' : 'col-md-6'] +
          ' col-xs-12 shadow-1 row justify-center col-6 q-mt-lg q-pa-md rounded-borders item section'
        "
        v-for="(item, i) in statusArray_old"
        :key="i"
        style="width: 50%"
      >
        <div class="row justify-start items-center full-width">
          <div class="col-xs-4">
            <q-toolbar class="text-blue-7" style="backgroundcolor: #f6f8fa">
              <q-toolbar-title class="text-h6 text-bold q-pa-sm">
                {{ item.title }}
              </q-toolbar-title>
            </q-toolbar>
          </div>
          <div class="col-xs-4">
            <q-toolbar
              class="text-blue-7 text-center"
              style="backgroundcolor: #f6f8fa"
            >
              <q-toolbar-title class="text-body1 q-pa-sm">
                {{ item.array.length }} Subjects
              </q-toolbar-title>
            </q-toolbar>
          </div>
          <div class="col-xs-4">
            <div class="row justify-end items-end">
              <q-icon
                @click="item.show = !item.show"
                class="rounded-borders individual-bg cursor-pointer"
                :name="item.show ? 'expand_less' : 'expand_more'"
                color="blue-7"
                :size="iconSize"
              ></q-icon>
            </div>
          </div>
        </div>
        <div v-if="item.show" class="row q-col-gutter-x-md full-width">
          <div
            :class="colSize"
            v-for="subject in item.array"
            :key="subject._id"
            style="min-height: 250px; z-index: 5"
          >
            <Subject
              class="q-mt-md"
              draggable="true"
              :subject="subject"
              :studyObject="studyObject"
              @update-status="updateStatus"
              @update-grade="updateGrade"
              @show-path="showPath"
              @handle-drag-start="handleDragStart($event, subject)"
              @drag="handleDrag"
              @dragend="handleDragEnd"
            />
          </div>
        </div>
      </div>
      <div class="full-width row justify-center">
       <!-- <SbwlCarousel 
          @update-status="updateStatus"
          @update-grade="updateGrade"
          @allSbwlsDone="allSbwlsDone"
        /> -->
      </div>
      <div v-if="this.studyId !== 'wire' && this.studyId !== 'wire-23'" class="full-width row justify-center">
        <!--<FreeElectiveSelector @update-status="updateStatus" @update-grade="updateGrade" /> -->
      </div>
    </div>


    <!-- Cart Dialog @TODO: Auslagern in eigene Komponente 
        - Feature: Befüllt direkt das "Shopping Cart" im lv-store
    -->
    <div class="fixed-bottom row justify-center q-ma-md"  style="z-index: 6;">
      <LvCart
      :seamless="seamless"
      @update:seamless="updateSeamless"
      :droppedItems="droppedItems"
      :droppedEctsSum="droppedEctsSum"
      @drop="handleDrop"
      @removeDroppedItem="removeDroppedItem" />
    </div>
  </div>
</template>

<script>
import { getChecker, getStudyObject, getStudyPath } from "@/store/study_logic";
import { ref } from "vue";
import Subject from "@/components/study/Subject.vue";
import { useUserStore } from "@/store/user.store";
import { useLvStore } from "@/store/lv.store";

import { useQuasar } from "quasar";
import LvCart from "@/components/lvplaner/LvCart.vue"

export default {
  components: {
    Subject,
    LvCart,
  },
  

  setup() {
    let studyId = ref("wiso-ibw");
    const studyObject = ref({ ...getStudyObject(studyId.value) });
    const checker = getChecker(studyId.value);
    const userStore = useUserStore();
    const lvStore = useLvStore();
    const path = getStudyPath(studyId.value);
    const q = useQuasar();
    const changed = ref(false);

    return {
      studyId,
      studyObject,
      checker,
      path,
      userStore,
      lvStore,
      q,
      changed,
      cartopen: ref(false),
      seamless: ref(false),
    };
  },
  data() {
    return {
      ectsCount: ref(0),
      isRunningCheckCBK: ref(false),
      checkHauptstudiumValue: true,
      twoLvCheck: ref(false),
      droppedItems: ref([]),
      status: ref([
        {
          title: "STEOP",
          show: true,
          array: ref([
            /*
              _id: string,
              name: string,
              subject_type: string,
              ects: number,
              ch: number,
              color: string,
              status: string,
              grade: number,
              category: string
             */
          ]),
        },
        {
          title: "Common Body of Knowledge",
          show: true,
          array: ref([]),
        },
        {
          title: "Hauptstudium",
          show: true,
          array: ref([]),
        },
      ]),
    };
  },
  computed: {
    steop() {
      return this.studyObject.subjectStatusEntry.slice(0, 4);
    },
    cbk() {
      return this.studyObject.subjectStatusEntry.slice(3, 13);
    },
    hauptstudium() {
      return this.studyObject.subjectStatusEntry.slice(13);
    },
    statusArray_old() {
      this.studyObject.study.subjects.forEach(
        (
          entry /* { subject_id: number, category: String, phase: STEOP | CBK | Hauptstudium */
        ) => {
          const subject = this.studyObject.subjects.find(
            (s) => s._id == entry.subject_id
          );

          if (subject) {
            const matchingStatus = this.status.find(
              (s) => s.title == entry.phase
            );
            if (matchingStatus) {
              const existingIndex = matchingStatus.array.findIndex(
                (s) => s._id == subject._id
              );
              if (existingIndex !== -1) {
                matchingStatus.array[existingIndex].name = subject.name;
                matchingStatus.array[existingIndex].category = entry.category;
              } else {
                matchingStatus.array.push({
                  // subject properties
                  _id: subject._id,
                  name: subject.name,
                  subject_type: subject.subject_type,
                  ects: subject.ects,
                  ch: subject.ch,
                  color: subject.color,
                  active: false,

                  // subject status entry properties
                  phase: entry.phase,
                  grade: entry.grade,
                  category: entry.category,
                });
              }
              const statusEntry = this.studyObject.subjectStatusEntry.find(
                (se) => se.subject_id == entry.subject_id
              );

              if (statusEntry) {
                matchingStatus.array.find((s) => s._id == subject._id).status =
                  statusEntry.status;
              }
            }
          }
        }
      );

      return this.status;
    },
    iconSize() {
      return this.$q.platform.is.desktop ? "md" : "xs";
    },
    fontSize() {
      return this.$q.platform.is.desktop? "text-h3" : "text-h5";
    },
    droppedEctsSum() {
      return this.droppedItems.reduce(
        (total, subject) => total + parseInt(subject.ects),
        0
      );
    },
    colSize() {
      if (this.$q.platform.is.desktop) {
        return 'col-xs-12 col-md-3'
      } else if (this.$q.screen.lt.sm) {
        return 'col-12'
      } else if (this.$q.screen.lt.md) {
        return 'col-6'
      } else {
        return 'col-xs-12 col-md-3'
      }
    }
  },
  methods: {
    ectsCounter() {
      let ectsSum = 0;
      for (let i = 0; i < this.studyObject.subjectStatusEntry.length; i++) {
        if (this.studyObject.subjectStatusEntry[i].status == "done") {
          const matchingSubject = this.studyObject.subjects.find(
            (subject) =>
              subject._id == this.studyObject.subjectStatusEntry[i].subject_id
          );
          if (matchingSubject) {
            ectsSum += matchingSubject.ects;
          }
        }
      }
      this.ectsCount = ectsSum;
    },
    updateStatus(subject, status) {
      subject.status = `${status}`;
      if (subject.status !== "done") {
        subject.grade = null;
      }
      for (let i = 0; i < this.studyObject.subjectStatusEntry.length; i++) {
        let id = this.studyObject.subjectStatusEntry[i].subject_id;
        if (subject._id == id) {
          this.studyObject.subjectStatusEntry[i].status = `${status}`;
          if (id.status !== "done") {
            this.studyObject.subjectStatusEntry[i].grade = null;
          }
        }
      }
    },

    /**
     * Update Grade geht einher mit dem Update des Status auf done
     * (ansonsten hat man keine Möglichkeit die Note zu ändern)
     *
     * @param {typeof<Subject && SubjectStatus>} subject
     * @param {string} grade String representation of a number (vuejs event bus things)
     */
    updateGrade(subject, grade) {
      try {
        const updated_grade = parseFloat(grade);

        if (updated_grade > 5 || updated_grade < 0) {
          // 0: grade not set
          throw new Error("Grade out of range");
        }

        for (let i = 0; i < this.studyObject.subjects.length; i++) {
          let id = this.studyObject.subjects[i]._id;
          if (subject._id == id && id.status == "done") {
            this.studyObject.subjects[i].grade = updated_grade;
          }
        }

        this.userStore.updateStudy(
          this.studyId,
          subject._id,
          subject.status,
          updated_grade,
          this.q.notify
        );
      } catch (e) {
        this.q.notify({
          message: "Grade out of range",
          color: "red-5",
          icon: "report_problem",
        });
        return;
      }
    },


    /* Zeigt den Path (Dependencies) des jeweiligen Studiengangs an */
    showPath(subject, event) {
      subject.active = true;
      console.log(event.target);
      console.log(event.currentTarget);

      const detectClick = (e) => {
        if (e.target !== event.target) {
          this.status.forEach((array) => {
            array.array.forEach((same) => {
              same.active = false;
            });
          });
          document.removeEventListener("click", detectClick);
        } else {
          subject.active = true;
        }

        if (subject.active) {
          // subject.color = "yellow"

          /* nimmt aus store/index.js den getStudyPath;
         getStudyPath nimmt aus store/paths den jeweiligen path.js abhängig von der studyId */

          this.path.executePath(subject, this.status);
        } else {
          const same = this.studyObject.subjects.find(
            (s) => s._id == subject._id
          );
          if (same) {
            subject.color = same.color;
          }
          this.status.forEach((array) => {
            array.array.forEach((same) => {
              this.studyObject.subjects.forEach((status) => {
                if (same._id == status._id) {
                  same.color = status.color;
                }
              });
            });
          });
        }
      };
      document.addEventListener("click", detectClick);
    },
    allSbwlsDone(allDone) {
      let sbwls = [];
      this.studyObject.study.subjects.forEach((sbwl) => {
        if (sbwl.category == "Spezielle Betriebswirtschaftslehre" || sbwl.category == "Specializations")  {
          const matchingStatus = this.studyObject.subjectStatusEntry.find((matchingSbwl) => matchingSbwl.subject_id == sbwl.subject_id)
          sbwls.push(matchingStatus)
        }
      })
      if (sbwls[0]) {
        if (sbwls[0].status == "can-do" && allDone.allDone) {
          sbwls[0].status = "done";
        } else if (sbwls[0].status == "done" && !allDone.allDone) {
          sbwls[0].status = "can-do";
        }
      } else {
        return
      }
      if (sbwls[1]) {
        if (sbwls[1].status == "can-do" && allDone.allDone2) {
          sbwls[1].status = "done";
        } else if (sbwls[1].status == "done" && !allDone.allDone2) {
          sbwls[1].status = "can-do";
        }
      } else {
        return
      }
      if (sbwls[2]) {
        if (sbwls[2].status == "can-do" && allDone.allDone3) {
          sbwls[2].status = "done";
        } else if (sbwls[2].status == "done" && !allDone.allDone3) {
          sbwls[2].status = "can-do";
        }
      } else {
        return
      }
    },
    updateSeamless(value) {
      this.seamless = value
    },  
    handleDrag(evt) {
      evt.preventDefault();
      /*nimmt drag kopie die in handleStart erstellt wird setzt opacity auf 1 und die position */
      const copyCard = document.querySelector(".drag-image");
      copyCard.style.opacity = "1";
      copyCard.style.left = evt.pageX - 100 + "px";
      copyCard.style.top = evt.pageY - 100 + "px";
    },
    handleDragStart(evt, subject) {
      /* Öffnet Dialog */
      this.seamless = true;

      /* Code für den dataTransfer der Subjects */
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("SubjectName", subject.name);
      evt.dataTransfer.setData("SubjectEcts", subject.ects);



      /* Code für die Drag Kopie */
      const copyCard = document.createElement("div");
      copyCard.className = "drag-image";
      copyCard.innerHTML = evt.target.innerHTML;
      document.body.appendChild(copyCard);
      copyCard.style.opacity = "0";
      /*nimmt drag kopie die in handleStart erstellt wird und ändert das Design */
      copyCard.style.position = "absolute";
      copyCard.style.width = "15rem";
      copyCard.style.height = "10rem";
      copyCard.style.borderRadius = "6px";
      copyCard.style.boxShadow =
        "0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)";
      copyCard.style.fontSize = "1px !important";
      copyCard.style.backgroundColor = "white";
      copyCard.style.zIndex = "5000";
      copyCard.style.pointerEvents = "none";


    /* Sucht in der Drag Kopie die Action Buttons unten und entfernt sie */
      const zweitesDiv = copyCard.querySelector(
        ".q-card__section.q-card__section--vert.items"
      );

      // Überprüfe, ob das zweite Div gefunden wurde, und entferne es, falls es existiert
      if (zweitesDiv) {
        zweitesDiv.parentNode.removeChild(zweitesDiv);
      }


      /* Verkleinert die Schriftgröße */
      const erstesDiv = copyCard.querySelector('.q-card__section.q-card__section--vert.banner.column.justify-end.items-start.q-pa-sm.cursor-pointer')
      if (erstesDiv) {
  // Finde das zweite Kind (Child) im ersten Haupt-Div
  const zweitesKind = erstesDiv.children[1]; // Beachte, dass die Indexierung bei 0 beginnt

  // Überprüfe, ob das zweite Kind gefunden wurde, und ändere die Schriftgröße, falls es existiert
  if (zweitesKind) {
    zweitesKind.style.fontSize = "16px"; // Hier kannst du die gewünschte Schriftgröße einstellen
  }
}
      evt.dataTransfer.setDragImage(copyCard, 0, 0);
    },
    handleDragEnd() {
      //this.seamless = false
      /* Entfernt Copycard wieder */
      const copyCard = document.querySelector('.drag-image');
        document.body.removeChild(copyCard)
    },
    handleDrop(evt) {
      let name = evt.dataTransfer.getData("SubjectName");
      let ects = evt.dataTransfer.getData("SubjectEcts");
      if (!this.droppedItems.some((item) => item.name == name)) {
        this.droppedItems.push({ name, ects });
        this.lvStore.addToCart(name, ects);
      } 
    },
    /* Wenn copycard über add Button gehoverd wird
    handleEnter() {
      this.seamless = true
    },*/
    /*handleLeave() {
      this.seamless = false
    },*/
    removeDroppedItem(index) {
      this.droppedItems.splice(index, 1);
      this.lvStore.removeFromCart(index);
    },
  },
  watch: {
    /* Überwacht die Status der Subjects */
    /* Order der Funktionen ist wichtig!! */
    "studyObject.subjectStatusEntry": {
      handler() {
        this.ectsCounter();
        this.checker.executeAll(
          this.studyObject,
          this.$data,
          this.isRunningCheckCBK,
          this.twoLvCheck,
          this.ectsCount
        );
      },
      deep: true,
    },
    steop: {
      handler() {
        this.checker.executeSteop(
          this.studyObject,
          this.$data,
          this.isRunningCheckCBK,
          this.twoLvCheck,
          this.ectsCount
        );
      },
      deep: true,
    },
    cbk: {
      handler() {
        this.ectsCounter();
        this.checker.executeCbk(
          this.studyObject,
          this.twoLvCheck,
          this.$data,
          this.ectsCount
        );
      },
      deep: true,
    },
  },
  mounted() {
    /* Wenn studyId = "bbe" dann wird CBK gehided */
    const container = document.querySelector(".container");
    const secondChild = container.children[1];
    if (this.studyId == "bbe") {
      secondChild.style.display = "none";
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#heading {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}
#heading-info-h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  color: $blue-4;
}
.item {
  transition: width 300ms;
}

.individual-bg:hover {
  background-color: $grey-2;
}
.badge {
  background-color: transparent;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 2;
}
.cart {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 75px;
  height: 75px;
  z-index: 5;
  border-radius: 50%;
  background-color: $blue-4;
  cursor: pointer;
  transition: all 250ms;
}
.cart:hover {
  background-color: #acdbf5;
}
.cart-open {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  background-color: $blue-4;
  margin: 3%;
  z-index: 1999;
  opacity: 0;
  transition: all 250ms ease;
}
.cart-open-button {
  opacity: 0;
}
.active {
  min-width: 35%;
  max-width: 45%;
  width: auto;
  height: auto;
  max-height: 65%;
  opacity: 1;
  background-color: white;
  margin: 1rem;
  overflow-y: auto;
}
.active-mobile {
  width: 90%;
  height: auto;
  max-height: 50%;
  opacity: 1;
  background-color: white;
}
.dragzone {
  border: dashed lightgrey;
  min-height: 200px;
  transition: all 200ms;
}
.dragover {
  background-color: rgb(240, 240, 240);
}
</style>