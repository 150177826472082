<template>
    <div>
            <StudyPlanView></StudyPlanView>
    </div>
</template>

<script>
import StudyPlanView from './StudyPlanView.vue';


export default {
    setup() {

        return {
        };
    },
    components: {
        StudyPlanView
    },
}
</script>

<style lang="scss" scoped>
</style>